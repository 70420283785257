import { useMutation, useQueryClient } from '@tanstack/react-query'

import { DeleteAllCampaignUsersResponseDto } from '~shared/types/campaign-user.dto'

import { queryKeys } from '~lib/constants/query'
import { ApiService } from '~lib/helpers/api'

export function useDeleteAllCampaignUsers({
  campaignId,
}: {
  campaignId: string
}) {
  const queryCache = useQueryClient()

  const callDeleteAllCampaignUsersApi = async () => {
    const response = await ApiService.delete<DeleteAllCampaignUsersResponseDto>(
      `/campaigns/${campaignId}/campaign-users`,
    )

    return response.data.deleted
  }

  const {
    isPending: isDeleteAllCampaignUsersLoading,
    mutateAsync: deleteAllCampaignUsers,
  } = useMutation({
    mutationFn: callDeleteAllCampaignUsersApi,
    onSuccess: async () => {
      await queryCache.invalidateQueries(queryKeys.campaigns.list)
    },
  })

  return {
    isDeleteAllCampaignUsersLoading,
    deleteAllCampaignUsers,
  }
}
