import {
  Box,
  HStack,
  Skeleton,
  Spacer,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { Button, useToast } from '@opengovsg/design-system-react'
import { Row } from '@tanstack/react-table'
import { useParams } from 'react-router-dom'
import { CamelCasedProperties } from 'type-fest'

import { UpdateLocationRequestDto } from '~shared/types/location.dto'

import { AdminCampaignLayout } from '~/layouts/AdminCampaignLayout'
import { LocationView } from '~/types/location'

import { AddLocationModal } from '../components/AddLocationModal'
import { BulkUploadLocationModal } from '../components/BulkUploadLocationModal'
import { UpdateLocationModal } from '../components/UpdateLocationModal'
import { useDeleteLocation } from '../hooks/useDeleteLocation'
import { useUpdateLocation } from '../hooks/useUpdateLocation'

import { PaginationNavigation, TableSkeleton } from '~components/DataTable'
import { LocationsTable } from '~components/LocationsTable'
import { useIsCampaignOnboardingDisabled } from '~features/campaign/hooks/useIsCampaignOnboardingDisabled'
import { useCampaign } from '~hooks/useCampaign'
import { useLocationCount } from '~hooks/useLocationCount'
import { useLocations } from '~hooks/useLocations'
import { ApiError } from '~lib/helpers/api'

export const LocationsPage = (): JSX.Element => {
  const { campaignId } = useParams() as { campaignId: string }
  const { campaign } = useCampaign(campaignId)
  const limit = 10
  const {
    locations,
    isLocationsLoading,
    getNextPageOfLocations,
    isFetchingLocationsNextPage,
    getPreviousPageOfLocations,
    isFetchingLocationsPreviousPage,
  } = useLocations(campaignId, limit)
  const { locationCount, isLocationCountLoading } = useLocationCount(campaignId)
  const { updateLocation, isUpdatingLocation } = useUpdateLocation(campaignId)
  const { deleteLocation, isDeletingLocation } = useDeleteLocation(campaignId)
  const isCampaignOnboardingDisabled =
    useIsCampaignOnboardingDisabled(campaignId)

  const {
    isOpen: isSingleAddOpen,
    onOpen: onSingleAddOpen,
    onClose: onSingleAddClose,
  } = useDisclosure()

  const {
    isOpen: isBulkAddOpen,
    onOpen: onBulkAddOpen,
    onClose: onBulkAddClose,
  } = useDisclosure()

  const showErrorToast = useToast({ status: 'error' })
  const showSuccessToast = useToast({ status: 'success' })

  const data = locations ?? []

  const count = locationCount ?? 0
  // boolean on whether or not to show location count
  // based on the total location count
  const shouldShowLocationCount = count > 0

  const locationCountString =
    data.length > 0
      ? `${Intl.NumberFormat('en').format(count)} ${
          count > 1 ? 'locations' : 'location'
        }`
      : ''

  const handleUpdateLocation = async (
    rowToUpdate: Row<LocationView>,
    data: unknown,
  ) => {
    // Data returned from modal is of type UpdateLocationFormState which is not a plain object
    // which needs to be transformed for snakecase-keys to be applied properly in updateLocationById
    // function.
    const updatedLocationName =
      data as CamelCasedProperties<UpdateLocationRequestDto>
    try {
      await updateLocation({
        locationId: rowToUpdate.original.id,
        updateLocationData: { ...updatedLocationName },
      })
      showSuccessToast({ title: 'You have successfully edited your location.' })
    } catch (e) {
      if (e instanceof ApiError) {
        showErrorToast({
          title: 'Unable to update location',
          description: e.message,
        })
      } else {
        showErrorToast({
          title: 'Unable to update location',
        })
      }
    }
  }

  const handleDeleteLocation = async (rowToDelete: Row<LocationView>) => {
    try {
      await deleteLocation(rowToDelete.original.id)
      showSuccessToast({ title: 'You have successfully deleted a location.' })
    } catch (e) {
      if (e instanceof ApiError) {
        showErrorToast({
          title: 'Unable to delete location',
          description: e.message,
        })
      } else {
        showErrorToast({
          title: 'Unable to delete location',
        })
      }
    }
  }

  return (
    <AdminCampaignLayout
      campaignId={campaignId}
      campaignName={campaign?.name}
      campaignStatus={campaign?.status}
      sidebarActiveIndex={0}
    >
      <VStack
        alignItems="stretch"
        spacing={8}
        py={12}
        px={6}
        bg="base.canvas.alt"
        minH="$100vh"
        // take up the remaining width of it's parent HStack
        w="100%"
        // override the base margin
        ml="0px !important"
      >
        <HStack justifyContent="space-between" alignItems="flex-end">
          <VStack alignItems="flex-start" spacing={1}>
            <Text textStyle="h3" fontWeight="600" color="base.content.default">
              Locations
            </Text>
            {shouldShowLocationCount && (
              // show skeleton till the query to get location count
              // has completed
              <Skeleton
                isLoaded={!isLocationCountLoading}
                startColor="grey.100"
                endColor="grey.50"
              >
                <Text textStyle="subhead-1" textColor="brand.secondary.500">
                  {locationCountString}
                </Text>
              </Skeleton>
            )}
          </VStack>
          <Spacer />
          <Button
            onClick={onBulkAddOpen}
            isDisabled={isCampaignOnboardingDisabled}
          >
            <Text textStyle="subhead-2">Bulk Upload Locations</Text>
          </Button>
          <Button onClick={onSingleAddOpen}>
            <Text textStyle="subhead-2">Add Location</Text>
          </Button>
        </HStack>
        <Box>
          {isLocationsLoading ? (
            <TableSkeleton />
          ) : data.length > 0 ? (
            <LocationsTable
              data={data}
              enableRowEditOptions={true}
              UpdateRowModal={UpdateLocationModal}
              handleUpdateRow={handleUpdateLocation}
              isUpdatingRow={isUpdatingLocation}
              handleDeleteRow={handleDeleteLocation}
              isDeletingRow={isDeletingLocation}
            />
          ) : (
            <Text>There are no locations yet.</Text>
          )}
        </Box>
        <PaginationNavigation
          onClickNext={getNextPageOfLocations}
          isLoadingNextPage={isFetchingLocationsNextPage ?? false}
          onClickPrevious={getPreviousPageOfLocations}
          isLoadingPreviousPage={isFetchingLocationsPreviousPage ?? false}
          limit={limit}
        />
      </VStack>

      <AddLocationModal
        isOpen={isSingleAddOpen}
        onOpen={onSingleAddOpen}
        onClose={onSingleAddClose}
        campaignId={campaignId}
      />
      <BulkUploadLocationModal
        campaignId={campaignId}
        isOpen={isBulkAddOpen}
        showDataOverrideWarning={data.length > 0}
        onOpen={onBulkAddOpen}
        onClose={onBulkAddClose}
      />
    </AdminCampaignLayout>
  )
}
